import classNames from "classnames";
import "./Header.scss";

function Header({ idElement, onSelectMenu, delayAnimation }) {
  return (
    <div className="header" id={idElement}>
      <div className="header-wrapper">
        <div
          className={classNames("header-left", {
            animate: delayAnimation,
          })}
        >
          <div className="title-header">
            Elevate your Business with Expert{" "}
            <b>Digital Marketing & Social Media Management</b>
          </div>
          <p className="content-header">
            MAYFIELD PMS is a experienced Digital Marketing Team specialize in
            Social Media Management. We plan and prepare adequate solution, and
            marketplace strategies tailor-made for your goals.{" "}
          </p>
          <button
            type="button"
            className="contact-btn"
            onClick={() => onSelectMenu("contact")}
          >
            Contact us
          </button>
        </div>
        <div
          className={classNames("header-right", {
            animate: delayAnimation,
          })}
        >
          <img
            className="img-cpu"
            alt=""
            src={require("assets/images/icons/cpu.png")}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
