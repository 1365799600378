import classNames from "classnames";
import { useEffect, useState } from "react";
import "./Sec2.scss";

function Sec2({ scrollPosition }) {
  const [animateFirst, setAnimateFirst] = useState(false);
  const [animateSecond, setAnimateSecond] = useState(false);
  const [animateThird, setAnimateThird] = useState(false);
  const [animateFourth, setAnimateFourth] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 800) {
      if (scrollPosition >= 600) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 1000) {
        setAnimateSecond(true);
      }
      if (scrollPosition >= 1300) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 1700) {
        setAnimateFourth(true);
      }
    } else if (window.innerWidth <= 800 && window.innerWidth > 767) {
      if (scrollPosition >= 1200) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 1700) {
        setAnimateSecond(true);
      }
      if (scrollPosition >= 2200) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 2700) {
        setAnimateFourth(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 1600) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 2000) {
        setAnimateSecond(true);
      }
      if (scrollPosition >= 2600) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 3000) {
        setAnimateFourth(true);
      }
    } else {
      if (scrollPosition >= 800) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 1300) {
        setAnimateSecond(true);
      }
      if (scrollPosition >= 1700) {
        setAnimateThird(true);
      }
      if (scrollPosition >= 2100) {
        setAnimateFourth(true);
      }
    }
  }, [scrollPosition]);

  return (
    <div className="sec2">
      <div
        className={classNames("sec2-item1", {
          animate: animateFirst,
        })}
      >
        <div className="left-item">
          <div className="icon-service">
            <img
              src={require("assets/images/icons/web-icon.png")}
              alt="Content Creation & Management"
            />
          </div>
          <div className="content-sec2">
            <h1>Content Creation & Management</h1>
            <p>
              From social strategy to creating content with our in-house
              production teams, we believe in creating a rewarding experience
              for the brand and its audience. Using data-driven strategies will
              offer a comprehensive suite of performance marketing solutions.
            </p>
          </div>
        </div>
        <div className="right-item">
          <img
            src={require("assets/images/icons/web-img.png")}
            alt="Content Creation & Management"
            className="icon-service"
          />
        </div>
      </div>
      <div
        className={classNames("sec2-item2", {
          animate: animateSecond,
        })}
      >
        <div className="left-item">
          <img
            src={require("assets/images/icons/mobile-img.png")}
            alt="Meta & Google Ads"
            className="icon-service"
          />
        </div>
        <div className="right-item">
          <div className="icon-service">
            <img
              src={require("assets/images/icons/mobile-icon.png")}
              alt="Meta & Google Ads"
            />
          </div>
          <div className="content-sec2">
            <h1>Meta & Google Ads</h1>
            <p>
              This ensures that the ad budget is spent on generating actual
              traffic to a website or landing page. Our specialists are experts
              who manage these campaigns by conducting keyword research, setting
              up ad groups, optimizing bids, and monitoring performance to
              maximize ROI.
            </p>
          </div>
        </div>
      </div>
      <div
        className={classNames("sec2-item3", {
          animate: animateThird,
        })}
      >
        <div className="left-item">
          <div className="icon-service">
            <img
              src={require("assets/images/icons/software-icon.png")}
              alt="Influencer Marketing"
            />
          </div>
          <div className="content-sec2">
            <h1>Influencer Marketing</h1>
            <p>
              This approach involves partnering with influencers to create
              authentic, engaging content that engages followers, with the aim
              of driving brand awareness, engagement, and ultimately product
              sales.
            </p>
          </div>
        </div>
        <div className="right-item">
          <img
            src={require("assets/images/icons/software-img.png")}
            alt="Influencer Marketing"
            className="icon-service"
          />
        </div>
      </div>
      <div
        className={classNames("sec2-item4", {
          animate: animateFourth,
        })}
      >
        <div className="left-item">
          <img
            src={require("assets/images/icons/cpu.png")}
            className="icon-service"
            alt="Analytics & Insight"
          />
        </div>
        <div className="right-item">
          <div className="icon-service">
            <img
              src={require("assets/images/icons/outsource-icon.png")}
              alt="Analytics & Insight"
            />
          </div>
          <div className="content-sec2">
            <h1>Analytics & Insight</h1>
            <p>
              Track performance with real-time analytics to refine and optimize
              your strategy. to help brands drive tangible business results
              while ensuring accountability for every ad spend.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sec2;
