import classNames from "classnames";
import { useEffect, useState } from "react";
import "./Sec1.scss";

const listService = [
  {
    img: "web1-icon.png",
    title: "Content Creation & Management.",
    content: "TopDuo community",
  },
  {
    img: "mobile1-icon.png",
    title: "Meta & Google Ads",
    content: "TopDuo community",
  },
  {
    img: "software1-icon.png",
    title: "Influencer Marketing",
    content: "TopDuo community",
  },
  {
    img: "outsource1-icon.png",
    title: "Analytics & Insight",
    content: "TopDuo community",
  },
];

function Sec1({ scrollPosition }) {
  const [classNameShow, setClassNameShow] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 450) {
      if (scrollPosition >= 150) {
        setClassNameShow(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 200) {
        setClassNameShow(true);
      }
    } else {
      if (scrollPosition >= 250) {
        setClassNameShow(true);
      }
    }
  }, [scrollPosition]);
  return (
    <div className="sec1">
      {listService.length > 0 &&
        listService.map((item, index) => (
          <div
            className={classNames("sec1-item", {
              show1: classNameShow,
            })}
            key={index}
          >
            <img
              src={require(`assets/images/icons/${item.img}`)}
              alt={item.title}
              className="img-sec1"
            />
            <h3 className="title-sec1">{item.title}</h3>
            {/* <p className="content-sec1">{item.content}</p> */}
          </div>
        ))}
    </div>
  );
}

export default Sec1;
