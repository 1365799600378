import Popup from "components/Popup/Popup";
import PopupSubmitSuccess from "components/Popup/PopupSubmitSuccess/PopupSubmitSuccess";
import { useRef, useState } from "react";
import "./Contact.scss";

function Contact(props) {
  const { idElement } = props;
  const form = useRef();
  // const recaptchaRef = useRef();

  const [showPopup, setShowPopup] = useState(false);
  // const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const [isError, setIsError] = useState(0);
  // const [recaptchaValue, setRecaptchaValue] = useState(null);

  // const handleChangeRecaptchaValue = (value) => {
  //   setIsRecaptchaVerified(true);
  //   setRecaptchaValue(value);
  // };

  const sendEmail = (e) => {
    e.preventDefault();

    // const name = e.target.user_name.value;
    // const phone = e.target.user_phone.value;
    // const email = e.target.user_email.value;
    // const message = e.target.message.value;

    // const recaptchaToken = recaptchaValue;

    // if (!name.trim()) {
    //   setIsError(1);
    //   return;
    // }
    // if (!phone.trim()) {
    //   setIsError(2);
    //   return;
    // }
    // if (!email.trim()) {
    //   setIsError(3);
    //   return;
    // }

    // const serviceId = "service_r41jy99";
    // const templateId = "template_u0w6hqr";
    // const userId = "9z0aeR8i3mLhMH5eD";

    // const emailParams = {
    //   to_name: "Contact Eastar technology",
    //   to_email: "contact.eastartechnology@gmail.com",
    //   user_name: name,
    //   user_email: email,
    //   user_phone: phone,
    //   message: message,
    //   "g-recaptcha-response": recaptchaToken,
    // };

    // if (isRecaptchaVerified) {
    // setShowPopup(true);
    // setIsError(0);
    // setIsRecaptchaVerified(false);
    // setRecaptchaValue(null);
    // recaptchaRef.current.reset();
    // emailjs.send(serviceId, templateId, emailParams, userId).then(
    //   (result) => {
    //     console.log("Send success!");
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   }
    // );
    e.target.reset();
    // } else {
    //   return;
    // }
  };

  return (
    <div className="contact" id={idElement}>
      <h1>Get Your Free Audit Now!</h1>
      <p className="slogan">
        Contact us for a free consultation and start achieving measurable
        results with our social media and digital marketing services.
      </p>
      <form
        encType="multipart/form-data"
        className="form-contact"
        ref={form}
        onSubmit={sendEmail}
      >
        <div className="info-user">
          <div className="input-name">
            <input
              type="text"
              className="input-item"
              placeholder="Enter your name"
              name="user_name"
            />
            {isError === 1 && <span className="error">*Required</span>}
          </div>
          <div className="input-phone">
            <input
              type="number"
              className="input-item"
              placeholder="Enter your phone number"
              name="user_phone"
            />
            {isError === 2 && <span className="error">*Required</span>}
          </div>
        </div>
        <div className="input-email">
          <input
            type="email"
            className="input-item"
            placeholder="Enter your email"
            name="user_email"
          />
          {isError === 3 && <span className="error">*Required</span>}
        </div>
        <textarea
          rows={5}
          className="input-detail"
          placeholder="More detail"
          name="message"
        />
        {/* <ReCAPTCHA
          ref={recaptchaRef}
          onChange={handleChangeRecaptchaValue}
          sitekey="6LdjL3cmAAAAAIyQEWTQ-C2VRk8sGyaQySOzf43Z"
        /> */}
        <button type="submit" className="send-btn">
          SEND US
        </button>
      </form>
      {showPopup && (
        <Popup isShow={showPopup} onClose={() => setShowPopup(false)} center>
          <PopupSubmitSuccess
            onClose={() => setShowPopup(false)}
            content="You have submitted successfully"
          />
        </Popup>
      )}
    </div>
  );
}

export default Contact;
