import classNames from "classnames";
import { useEffect, useState } from "react";
import "./Sec3.scss";

const listService = [
  {
    img: "internet.png",
    title: "STRATEGY & CONSULTANCY",
  },
  {
    img: "android.png",
    title: "BRANDING, CREATIVE & DESIGN",
  },
  {
    img: "ios.png",
    title: "SOCIAL MEDIA",
  },
  {
    img: "iot.png",
    title: "VIDEO, AUDIO & ANIMATION",
  },
  {
    img: "wearalabel.png",
    title: "PR",
  },
  {
    img: "tv.png",
    title: "MEDIA PLANNING & BUYING",
  },
];

const checkedItems = [
  {
    id: 1,
    text: "Maximum reach campaigns",
  },
  {
    id: 2,
    text: "Grow your followers",
  },
  {
    id: 3,
    text: "Increase daily traffics",
  },
  {
    id: 4,
    text: "Improve conversion rates",
  },
];

function Sec3({ scrollPosition }) {
  const [animateFirst, setAnimateFirst] = useState(false);
  const [animateSecond, setAnimateSecond] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 800) {
      if (scrollPosition >= 2100) {
        setAnimateFirst(true);
        setAnimateSecond(true);
      }
    } else if (window.innerWidth <= 800 && window.innerWidth > 767) {
      if (scrollPosition >= 3200) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 3600) {
        setAnimateSecond(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 3600) {
        setAnimateFirst(true);
      }
      if (scrollPosition >= 4000) {
        setAnimateSecond(true);
      }
    } else {
      if (scrollPosition >= 2400) {
        setAnimateFirst(true);
        setAnimateSecond(true);
      }
    }
  }, [scrollPosition]);

  return (
    <div className="sec3">
      <div
        className={classNames("main-left", {
          animate: animateFirst,
        })}
      >
        <h1>We have the expertise to make it happen.</h1>
        <p className="content-left">
          Partner with MAYFILED PMS to create innovative digital marketing
          strategies that align with your brand's goals. Whether you want to:
        </p>
        <div className="list-checked">
          {checkedItems.map((item) => (
            <div key={item.id} className="checked-item">
              <img
                src={require("assets/images/icons/checked.png")}
                className="icon-checked"
                alt="Checked Icon"
              />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames("main-right", {
          animate: animateSecond,
        })}
      >
        {listService.length > 0 &&
          listService.map((item, index) => (
            <div className="sec3-item" key={index}>
              <img
                alt={item.title}
                src={require(`assets/images/icons/${item.img}`)}
                className="img-item"
              />
              <p className="title-item">{item.title}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Sec3;
