import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="content-footer">
          <div className="main-left">
            <img
              className="heading-logo"
              alt=""
              src={require("assets/images/logos/logo.png")}
            />
            <p className="left-content">
              MAYFIELD PMS is a experienced Digital Marketing Team specialize in
              Social Media Management. We plan and prepare adequate solution,
              and marketplace strategies tailor-made for your goals.
            </p>
          </div>
          <div className="main">
            <h3 className="heading-main">Contact us</h3>
            <div className="main-content flex-column">
              {/* <div className="info-footer flex-row">
              <h5 className="title-info">Phone number: </h5>
            </div> */}
              <div className="info-footer flex-row">
                <h5 className="title-info">Email: mayfield.pms@gmail.com</h5>
              </div>
              <div className="info-footer flex-row">
                <h5 className="title-info">
                  Address: Arab Bank Building Baniyas Rd - Deira - Rigga Al
                  Buteen - Dubai
                </h5>
              </div>
              <div className="info-footer flex-row">
                <h5 className="title-info">Contact number: +971 58 146 0088</h5>
              </div>
            </div>
          </div>
          <div className="main-right">
            <h3 className="heading-main">Jobs</h3>
            <div className="main-content flex-column">
              <div className="info-footer flex-row">
                <h5 className="title-jobs">Jobs opening</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="title-footer">
          <p className="copyright">
            Copyright ® 2024 MAYFIELD PROJECT MANAGEMENT SERVICE EST.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
